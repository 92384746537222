.header__controls{

  .ant-tabs-top > .ant-tabs-nav::before,.ant-menu-horizontal {
    border-bottom: none;
  }

  height: 65px;
  background-color: var(--color-white-1);
  display:flex;
  justify-content:flex-end;
  align-items: center;
  .header-tabs{
    width :310px;
  }

  .header--logo{
    display:flex;
    align-items: center;
    margin-right:10px;
  }

  .vertical--divider{
    border:1px solid var(--color-black-1);
    height:80%;
  }

  .ant-tabs-nav{
    margin:0 !important;
  }

  &--control-item{
    min-height: 90%;
    min-width:70px;
    width:fit-content;
    ul{
      height:50px;
    }
  }

  &--expand-to-full-screen-width{
    width:100vw;
    border-bottom:1px solid var(--color-gray-8);
  }
}
